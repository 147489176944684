/**
 * @file Customization UI injection into parent page and API
 *
 * @supported IE8+
 */

import YETI from 'yeti';

/**
 * Initialize the widget iframe and customizer namespace properties
 *
 * @constructor
 */
function customizer() {

    /**
     * Will be set to true once all assets are loaded
     *
     * @type {boolean}
     */
    this.ready = false;

    /**
     * Build the iframe and load the Customizer with given options
     *
     * @param opts
     * @returns {*}
     */
    this.init = opts => {

        if (!opts.selector) {
            return YETI.error('No target selector defined');
        }

        const target = document.querySelector(opts.selector);

        if (!target) {
            return YETI.error('Target selector not found');
        }

        // Expose properties required for app consumption
        this.options = opts;
        this.targetSelector = opts.selector;
        this.iframeSelector = '[data-ycs="customizer"]';

        const iframe = document.querySelector(this.iframeSelector);

        if (iframe) {
            iframe.remove();
        }

        // Build iframe
        const iframeNew = document.createElement('iframe');
        const timestamp = Date.now();
        const cssUrl = YETI.appHost + 'app.css?_=' + timestamp;
        const jsUrl = YETI.appHost + 'app.min.js?_=' + timestamp;
        const html = '<!doctype html>\n<html><head><link href="' + cssUrl + '" rel="stylesheet" type="text/css">' +
            '<link rel="stylesheet" href="https://use.typekit.net/tiq3kbk.css">' +
            '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">' +
            '</head><body><div class="loading" data-role="loading"><div class="loader"></div></div>' +
            '<div id="app" data-ui="app-container">' +
            '<a data-template="app" data-selector="#app" data-publish="app-loaded" data-auto-load></a></div>' +
            '<script src="' + jsUrl + '"></script></body></html>';

        iframeNew.setAttribute('data-ycs', 'customizer');
        iframeNew.setAttribute('title', 'YETI Customizer');
        iframeNew.style.cssText = 'width:100%;padding:0;margin:0;border:none;min-height:200px;display:none;';

        // Prepend the iframe to the target selector
        target.insertBefore(iframeNew, target.firstChild);

        // Set references to the iframe window and document objects
        const iwin = iframeNew.contentWindow;
        const idoc = iwin.document;

        // Write document layout
        idoc.open('text/html', 'replace');
        idoc.write(html);
        idoc.close();

        // Set a reference to the script object
        const js = idoc.body.querySelector('script');

        // Load the API
        js.addEventListener('load', () => {
            this.open = _opts => iwin.designer.open(_opts);
            this.close = () => iwin.designer.close();
            this.getJSON = () => iwin.designer.getJSON();
            this.setJSON = data => iwin.designer.setJSON(data);
            this.approve = () => iwin.designer.approve();
            this.getData = () => iwin.designer.getData();
            this.getProducts = () => iwin.designer.getProducts();
            this.getCategories = () => iwin.designer.getCategories();
        });

        return true;
    };

}

/**
 * Attach YETI to the browser's `window` namespace
 */
if (window.YETI === undefined) {
    window.YETI = YETI;
}
window.YETI.customizer = new customizer();