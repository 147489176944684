import * as env from '../../_tmp/environment';

export default {

    /**
     * App assets host URL
     *
     * @type {string}
     */
    appHost: env.appHost,

    /**
     * Data host URL
     *
     * @type {string}
     */
    dataHost: env.dataHost,

    /**
     * Custom error handling
     *
     * @param msg
     */
    error: msg => {
        throw 'YETI: ' + msg;
    }
};